<template>
  <div class="flex justify-content-start visible-sm">
    <custom-input
      label="Имя"
      name="name"
      v-model="searchParams.nameField"
      clear
    />
  </div>
  <div v-if="!loadingTable">
    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="70"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      :isExternalFilterPresent="isExternalFilterPresent"
      :doesExternalFilterPass="doesExternalFilterPass"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
      @filterChanged="filterChanged"
    ></ag-grid-vue>

    <table-pagination
      :gridApi="gridApi"
      :totalPages="gridApi ? gridApi.paginationGetTotalPages() : 0"
      :countOnPage="countOnPage"
      :activeFilter="activeFilter"
    />
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import EditRenderer from "@/components/Tables/CellRenderers/EditRenderer.vue";
import ImageRenderer from "@/components/Tables/CellRenderers/ImageRenderer.vue";
import NewPartnerRenderer from "@/components/Tables/CellRenderers/NewPartnerRenderer.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import CustomTextFilter from "@/components/Tables/FiltersComponents/CustomTextFilter.vue";
import TextLinkRenderer from "@/components/Tables/CellRenderers/TextLinkRenderer.vue";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import CustomSelectFilter from "@/components/Tables/FiltersComponents/CustomSelectFilter.vue";
import { useRoute } from "vue-router";
import UserLink from "@/components/Tables/CellRenderers/UserLink.vue";
import useCheckRights from "../../../mixins/useCheckRights";
import CustomDateFilter from "../../../components/Tables/FiltersComponents/CustomDateFilter.vue";
export default {
  name: "partners-table",
  components: {
    VPagination,
    AgGridVue,
    EditRenderer,
    ImageRenderer,
    Preloader,
    TextLinkRenderer,
    CustomInput,
    CustomTextFilter,
    TablePagination,
    CustomSelectFilter,
    NewPartnerRenderer,
    UserLink,
    CustomDateFilter,
  },
  setup() {
    const store = useStore(),
      gridApi = ref(null),
      columnApi = ref(null),
      route = useRoute();
    const { getRight } = useCheckRights();

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
      if (getRight("partnersList")) {
        columnApi.value.setColumnVisible("new", true);
        columnApi.value.setColumnVisible("partner", true);
        columnApi.value.applyColumnState({
          state: [{ colId: "new", sort: "desc" }],
        });
      }
    };
    const countOnPage = ref(15),
      activeFilter = ref(false),
      isPreloader = ref(false),
      searchParams = reactive({
        nameField: "",
        cityField: "",
      });
    const loadingTable = computed(() => store.state.ui.loadingTable);
    const reviews = computed(() => store.state.partners.reviews),
      rowData = computed(() => {
        return reviews.value.map((review) => {
          return {
            id: review.id,
            name: review.title,
            text: review.description,
            date: review.date,
            new: review.status,
            expert: review.expert?.title,
            partner: review.partner?.title,
            expert_id: review.expert?.id,
            partner_id: review.expert?.id,
            edit: review.id,
          };
        });
      });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "new",
          headerName: "",
          width: 160,
          flex: 0,
          hide: true,
          cellRenderer: "NewPartnerRenderer",
        },
        {
          field: "partner",
          headerName: "Партнер",
          wrapText: true,
          minWidth: 255,
          hide: true,
          filter: "CustomTextFilter",
          filterParams: {
            filterName: "Поиск по партнеру",
          },
          cellRenderer: "UserLink",
          cellRendererParams: {
            source: "partner_id",
            type: "partner",
          },
        },
        {
          field: "expert",
          headerName: "Эксперт",
          wrapText: true,
          minWidth: 255,
          filter: "CustomTextFilter",
          filterParams: {
            filterName: "Поиск по эксперту",
          },
          cellRenderer: "UserLink",
          cellRendererParams: {
            source: "expert_id",
            type: "expert",
          },
        },
        {
          field: "name",
          headerName: "Имя",
          wrapText: true,
          minWidth: 255,
          filter: "CustomTextFilter",
          filterParams: {
            filterName: "Поиск по названию",
          },
        },
        {
          field: "text",
          headerName: "Текст",
          wrapText: true,
          minWidth: 350,
          filter: "CustomTextFilter",
          filterParams: {
            filterName: "Поиск по тексту",
          },
        },
        {
          field: "date",
          headerName: "Дата",
          width: 220,
          flex: 0,
          filter: "CustomDateFilter",
          filterParams: {
            filterName: "Фильтр по дате",
          },
        },
        {
          field: "edit",
          headerName: "",
          width: 70,
          flex: 0,
          cellRenderer: "EditRenderer",
          cellRendererParams: {
            source: "review",
          },
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const doesExternalFilterPass = (node) => {
      return node.data.partner_id == route.query.id;
    };
    const isFiltered = ref(false);
    const isExternalFilterPresent = () => isFiltered.value;

    onBeforeMount(() => {
      store.commit("ui/setLoadingTable", true);
      store
        .dispatch("partners/getReviews")
        .finally(() => store.commit("ui/setLoadingTable", false));
      if (route.query.id) isFiltered.value = true;
    });

    watch(searchParams, () => {
      if (
        searchParams.nameField.length >= 3 ||
        searchParams.cityField.length >= 3
      ) {
        gridApi.value.setQuickFilter(
          `${
            searchParams.nameField.length >= 3 ? searchParams.nameField : ""
          } ${searchParams.cityField.length >= 3 ? searchParams.cityField : ""}`
        );
      } else {
        gridApi.value.setQuickFilter(null);
      }
    });

    const filterChanged = () => {
      activeFilter.value = !activeFilter.value;
    };

    return {
      countOnPage,
      isPreloader,
      reviews,
      searchParams,
      activeFilter,
      loadingTable,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
      filterChanged,

      doesExternalFilterPass,
      isExternalFilterPresent,
    };
  },
};
</script>

<style lang="scss" scoped>
</style>
